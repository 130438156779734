import React from 'react';

import '../../styles.css';
import SEOComponent from '../components/fields/seoField';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';

const FairPlay = () => {
	return (
		<div className='static-page fairplay'>
			<CommonPageRenderer>
				<SEOComponent
					title='Fair Play - Snakes & Ladders Plus'
					description='Transparency Transparency Fair Play We at Zupee want to ensure a fair game for you every time you play with us. We highly value transparency and integrity which is further reflected in our Fairplay Policy. TRANSPARENCY Equal Opportunity Our games ensure equal opportunities for all our users. The game rules and regulations apply to every [...]'
				/>
				<div className='page-content mainContainer'>
					<h2 class='page-heading align-center'>Fair Play</h2>
					<p className='description'>
						We at Zupee want to ensure a fair game for you every time you play with us. We highly value transparency and
						integrity which is further reflected in our Fairplay Policy.
					</p>
					<h3 className='page-subheading align-center'>TRANSPARENCY</h3>
					<p className='description point-heading align-left'>
						<u>
							<b>Equal Opportunity</b>
						</u>
					</p>
					<p className='description'>
						Our games ensure equal opportunities for all our users. The game rules and regulations apply to every player
						equally. We take every measure possible to avoid hacking or cheating on our platform. Deadlines are put in
						place for joining each tournament to ensure every user has an equal opportunity at the game.
					</p>
					<p className='description point-heading align-left'>
						<u>
							<b>Playing & Winning</b>
						</u>
					</p>
					<p className='description'>
						It is important that our users know upfront what potential winnings they could take home if they win, when
						they sign up for a game. We want your experience on the games to be seamless and have taken the following
						steps to ensure you enter a tournament with full confidence:
					</p>
					<ul class='sub-page-text snl-list'>
						<li>
							The scoring system used for calculating points is clearly illustrated and displayed for every new user
							being onboarded on our platform
						</li>
						<li>
							The applicable play money for winners of each game is clearly mentioned. Any winnings/payouts are promptly
							processed and credited to the User’s account as per our{' '}
							<a class='sub-page-link' href='/terms-of-service-terms/'>
								Terms of Use
							</a>{' '}
							and other applicable Zupee policies.
						</li>
						<li>
							Any cancellation fee, or consequences of leaving the game mid-way or engaging in unfair gaming practices
							is also prescribed in this{' '}
							<a class='sub-page-link' href='/fair-play/'>
								Fairplay Policy
							</a>{' '}
							and the{' '}
							<a class='sub-page-link' href='/terms-of-service-terms/'>
								Terms of Use
							</a>
						</li>
					</ul>
					<h3 className='page-subheading align-center'>TRUST</h3>
					<p className='description point-heading align-left'>
						<u>
							<b>Genuine, Verified Users</b>
						</u>
					</p>
					<p className='description'>
						We strive to ensure that we only have genuine users playing Zupee games. There are no robots allowed on the
						Platform (As defined in the Terms). To facilitate this, we have put in place appropriate policies and
						protocols to check any violation of the Zupee FairPlay Policy. Before withdrawing any winnings, depending on
						the amount and/or frequency of the withdrawal, a user may be required to verify the user’s identity, and
						account – e.g.: copies of PAN card & bank account details. These details and documents are collected and
						verified as prescribed by respective law or by regulatory authorities either by us directly, or via a
						third-party service provider.
					</p>
					<p className='description point-heading align-left'>
						<u>
							<b>Safe & Secure Payments</b>
						</u>
					</p>
					<p className='description'>
						We at Zupee have integrated a safe & secure withdrawal process to ensure the smooth withdrawal of money for
						our users. We work with trusted third-party payment gateways with 3-D secure authentication that guarantees
						the safety and security of all financial transactions. We do not store your financial/payment instruments like
						credit cards and debit cards, UPI handles, and wallet details with us and these are stored only with
						authorized technology service providers and entities permitted to store such data in accordance with
						applicable laws and regulatory directives.
					</p>
					<p className='description point-heading align-left'>
						<u>
							<b>Communication</b>
						</u>
					</p>
					<p className='description'>
						Keeping in mind the importance of being accessible to our users, Zupee has built processes where users can
						reach out and raise issues on our Helpdesk section. Resolutions to issues raised are provided within a short
						span and can be easily tracked by the users.
					</p>
					<h3 className='page-subheading align-center'>RESPONSIBILITY</h3>
					<p className='description point-heading align-left'>
						<u>
							<b>Protecting User Information</b>
						</u>
					</p>
					<p className='description'>
						Zupee deploys industry leading data security protocols and measures to protect personal user information like
						PAN card details and bank account details. Any personal information shared with us is only used for user
						verification, and is not disclosed to any outside parties except for in special cases where it is required to
						use their services for verification, or unless required to be disclosed under compulsion of law, or by law
						enforcement, regulatory or judicial authorities as provided in our Privacy Policy.
					</p>
					<p className='description point-heading align-left'>
						<u>
							<b>Responsible Play</b>
						</u>
					</p>
					<p className='description'>
						Users below 18 years of age are not permitted to access or use the games provided on the Zupee Platform. We do
						not allow users from certain States to play our games, as per the prevalent law in such states. The users are
						liable to ensure compliance with the applicable laws in their states and Zupee is not liable for any breach of
						such laws by the User. We don’t allow our users to participate in more than one tournament at the same time.
						You can read more about our Responsible Gaming policies in the Responsible Gaming section.
					</p>
					<p className='description point-heading align-left'>
						<u>
							<b>Compliance with Law</b>
						</u>
					</p>
					<p className='description'>
						Zupee is a skill-based platform that can help gamers improve their strategic prowess, decision making, and
						time-management skills. Our games are 100% legal and are compliant with all the laws and regulations
						pertaining to the respective states.
					</p>
					<h3 className='page-subheading align-center'>RNG</h3>
					<p className='description'>
						A “Random Number Generator” (RNG) certificate is a sign of approval from a certified RNG testing body,
						ensuring statistical randomness in the numbers generated by the application elements like the dice etc. These
						standards establish that the numbers generated are entirely random and unpredictable, thus promoting honest
						gameplay.{' '}
						<a class='sub-page-link' href='/certification' target='_blank'>
							Click to view to the certificate
						</a>
					</p>
					<h3 className='page-subheading align-center'>FAIRPLAY AND PREVENTION OF COLLUSION AND FRAUD POLICY</h3>
					<ul class='sub-page-text snl-list'>
						<li>
							Zupee is committed to promoting fair play on the platform. We might track gameplay to enforce our terms
							and policies, and appropriate action may be taken against any player(s) caught indulging in any form of
							abuse of policies, and/or collusion. By agreeing to the Terms and Conditions of this Platform, you agree
							not to indulge in any unfair game practice that might provide undue advantage to you or any other player.
						</li>
						<li>
							Any player found to be using duplicate accounts or using another person’s account for unfair activities,
							for example, alleged or found adding money or withdrawing from/to a an account suspected of being
							fraudulent, is liable to face legal consequences including, but not limited to, withholding any withdrawal
							or use of the player’s balance and locking of the user’s account.
						</li>
						<li>
							We reserve the right to take any necessary action, as we deem fit, against users who indulge in unfair
							means and practices. The action could range from penalties like temporary account suspension, withdrawal
							blocking, to permanent account deactivation. If you suspect any player colluding in any game or involved
							in unfair practices, you can write to us at{' '}
							<a class='sub-page-link' href='mailto:support@snakesandladdersplus.com'>
								support@snakesandladdersplus.com
							</a>
						</li>
						<li>
							Please note that we can terminate or suspend your Account and all associated accounts, institute fraud
							prevention measures such as access restrictions, transaction velocity restrictions etc. without prior
							notice to prevent unauthorised use of our Accounts. You must cooperate with us in any investigation and
							use any fraud prevention measures we prescribe, and you agree that we will not be liable to you or any
							third party for any block, suspension, cancellations, or termination of your use of an Account owing to
							such measures
						</li>
					</ul>
				</div>
			</CommonPageRenderer>
		</div>
	);
};

export default FairPlay;
